export const formatAddress = (address: string): string => {
  return `${address.slice(0, 8)}...${address.slice(-5)}`;
};

export const post = async (
  urls: string | string[],
  body: any,
  options?: { signal?: AbortSignal },
): Promise<any> => {
  const fetchData = {
    method: 'POST',
    body,
    signal: options?.signal,
  };
  try {
    const response = await fetch(Array.isArray(urls) ? urls[0] : urls, fetchData);
    return response.json();
  } catch (e: any) {
    if (e.name === 'AbortError') {
      console.log('Request was aborted');
    } else {
      console.error('Error in post request:', e);
    }
    throw e;
  }
};

export const timeAgo = (timestamp: number): string => {
  const now = Date.now();
  const secondsAgo = Math.floor((now - timestamp) / 1000);

  if (secondsAgo < 60) {
    return `${secondsAgo} second${secondsAgo !== 1 ? 's' : ''} ago`;
  } else if (secondsAgo < 3600) {
    const minutesAgo = Math.floor(secondsAgo / 60);
    return `${minutesAgo} minute${minutesAgo !== 1 ? 's' : ''} ago`;
  } else if (secondsAgo < 86400) {
    const hoursAgo = Math.floor(secondsAgo / 3600);
    return `${hoursAgo} hour${hoursAgo !== 1 ? 's' : ''} ago`;
  } else if (secondsAgo < 2592000) {
    const daysAgo = Math.floor(secondsAgo / 86400);
    return `${daysAgo} day${daysAgo !== 1 ? 's' : ''} ago`;
  } else if (secondsAgo < 31536000) {
    const monthsAgo = Math.floor(secondsAgo / 2592000);
    return `${monthsAgo} month${monthsAgo !== 1 ? 's' : ''} ago`;
  } else {
    const yearsAgo = Math.floor(secondsAgo / 31536000);
    return `${yearsAgo} year${yearsAgo !== 1 ? 's' : ''} ago`;
  }
};

export function formatDecimal(
  value: number | string,
  decimalPlaces = 2,
  thousandSeparator = ',',
  decimalSeparator = '.',
): string {
  if (isNaN(Number(value))) {
    throw new Error('Value must be a valid number or a string representing a number.');
  }
  const num = parseFloat(value.toString());
  const multiplier = Math.pow(10, decimalPlaces);
  const roundedDown = Math.floor(num * multiplier) / multiplier;
  const formattedNumber = roundedDown.toString();
  const [integerPart, decimalPart] = formattedNumber.split('.');
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
  if (!decimalPart) {
    return formattedIntegerPart;
  }
  return formattedIntegerPart + decimalSeparator + decimalPart;
}
