import { wallet } from '@cityofzion/neon-js';
import { NeoContext } from 'App';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useAccount } from 'wagmi';
import neoIcon from 'assets/images/neo.svg';
import neoxIcon from 'assets/images/x.svg';
import { formatAddress, formatDecimal, post, timeAgo } from 'utils/tools';

const NEOX_BLOCK_EXPLORER = process.env.REACT_APP_NEOX_BLOCK_EXPLORER as string;
const NEO_BLOCK_EXPLORER = process.env.REACT_APP_NEO_BLOCK_EXPLORER as string;
const BRIDGE_CONTRACT = process.env.REACT_APP_NEO_BRIDGE_CONTRACT as string;
const NEO_FURA = process.env.REACT_APP_NEO_FURA as string;
const LIMIT = 10;

type Transaction = {
  txid: string;
  from: string;
  to: string;
  amount: string;
  symbol: string;
  timestamp: number;
};

type Props = {
  tokenMap: Record<string, { n3Contract: string }>;
};

export const HistoryChart: FC<Props> = ({ tokenMap }) => {
  const { address: neoxAddress } = useAccount();
  const { address: n3Address } = useContext(NeoContext);

  const [currentTab, setCurrentTab] = useState<'deposit' | 'withdraw'>('deposit');
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState({
    deposit: 0,
    withdraw: 0,
  });

  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    abortOngoingRequest();
    setTransactions([]);
    setHasMore(true);
    setSkip(prev => ({ ...prev, withdraw: 0 }));
  }, [neoxAddress, currentTab]);

  useEffect(() => {
    abortOngoingRequest();
    setTransactions([]);
    setHasMore(true);
    setSkip(prev => ({ ...prev, deposit: 0 }));
  }, [n3Address, currentTab]);

  useEffect(() => {
    fetchTransactions();
  }, [skip]);

  const abortOngoingRequest = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setLoading(false);
    }
    abortControllerRef.current = new AbortController();
  };

  const fetchTransactions = async () => {
    if (!hasMore || loading) return;
    setLoading(true);

    const isWithdraw = currentTab === 'withdraw';
    const address = isWithdraw ? neoxAddress : n3Address;
    if (!address) {
      setLoading(false);
      return;
    }

    try {
      const signal = abortControllerRef.current?.signal;

      const response = isWithdraw
        ? await fetch(
            `https://xt4scan.ngd.network:8877/api/v1/transactions/withdrawals?limit=${LIMIT}&skip=${skip.withdraw}&from=${neoxAddress}`,
            { signal },
          )
        : await post(
            NEO_FURA,
            JSON.stringify({
              jsonrpc: '2.0',
              method: 'GetBridgeDepositHistory',
              params: {
                ContractHash: BRIDGE_CONTRACT,
                Sender: `0x${wallet.getScriptHashFromAddress(n3Address)}`,
                Limit: LIMIT,
                Skip: skip.deposit,
              },
              id: 1,
            }),
            { signal },
          );

      const data = isWithdraw ? await response.json() : response?.result?.result || [];
      if (data.length < LIMIT) setHasMore(false);
      setTransactions(prev => [...prev, ...data]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const bottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;
    if (bottom && hasMore && !loading) {
      setSkip(skip => ({
        ...skip,
        deposit: currentTab === 'deposit' ? skip.deposit + LIMIT : skip.deposit,
        withdraw: currentTab === 'withdraw' ? skip.withdraw + LIMIT : skip.withdraw,
      }));
    }
  };

  return (
    <div className="z-1 relative mx-auto mt-[58px] w-[758px] rounded-[20px] border-[1px] border-text/20 bg-card sm:w-full">
      <div className="ml-[28px] mt-[28px] text-[20px] font-bold">History</div>
      <div className="ml-[28px] mt-[20px] flex items-center gap-[10px]">
        {['deposit', 'withdraw'].map(tab => (
          <div
            key={tab}
            className={`flex h-[38px] w-[108px] cursor-pointer items-center justify-center rounded-[50px] border-[1px] text-[14px] font-medium duration-200 hover:border-primary ${
              currentTab === tab ? 'border-primary' : 'border-text/20'
            }`}
            onClick={() => setCurrentTab(tab as 'deposit' | 'withdraw')}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </div>
        ))}
      </div>
      <div className="mt-[20px] h-[400px] w-full overflow-auto" onScroll={handleScroll}>
        <table className="mx-[28px] mb-[28px] w-[700px] table-fixed border-separate border-spacing-y-[20px] overflow-auto">
          <thead className="text-center">
            <tr>
              <th className="pl-[16px] text-left font-medium">Hash</th>
              <th className="text-left font-medium">From</th>
              <th className="text-left font-medium">To</th>
              <th className="w-[100px] pl-[16px] text-left font-medium">Value</th>
              <th className="w-[110px] pl-[16px] text-left font-medium">Time</th>
            </tr>
          </thead>
          <tbody className="mt-[20px] text-center">
            {transactions.map((transaction, index) => (
              <tr key={index} className="h-[44px] text-[12px] font-thin">
                <td className="rounded-bl-[10px] rounded-tl-[10px] border-[1px] border-r-0 border-text/20 pl-[16px] text-left">
                  <a
                    href={
                      currentTab === 'deposit'
                        ? `${NEO_BLOCK_EXPLORER}/transactionInfo/${transaction.txid}`
                        : `${NEOX_BLOCK_EXPLORER}/tx/${transaction.txid}`
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {formatAddress(transaction.txid)}
                  </a>
                </td>
                <td className="border-[1px] border-x-0 border-text/20">
                  <div className="flex items-center gap-[6px]">
                    <div className="flex h-[24px] w-[24px] shrink-0 items-center justify-center rounded-full bg-text p-[5px] sm:h-[18px] sm:w-[18px]">
                      <img className="w-full" src={currentTab === 'deposit' ? neoIcon : neoxIcon} />
                    </div>
                    <a
                      href={
                        currentTab === 'deposit'
                          ? `${NEO_BLOCK_EXPLORER}/accountprofile/${transaction.from}`
                          : `${NEOX_BLOCK_EXPLORER}/address/${transaction.from}`
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {currentTab === 'deposit'
                        ? formatAddress(
                            wallet.getAddressFromScriptHash(transaction.from.replace(/^0x/, '')),
                          )
                        : formatAddress(transaction.from)}
                    </a>
                  </div>
                </td>
                <td className="border-[1px] border-x-0 border-text/20">
                  <div className="flex items-center gap-[6px]">
                    <div className="flex h-[24px] w-[24px] shrink-0 items-center justify-center rounded-full bg-text p-[5px] sm:h-[18px] sm:w-[18px]">
                      <img className="w-full" src={currentTab === 'deposit' ? neoxIcon : neoIcon} />
                    </div>
                    <a
                      href={
                        currentTab === 'deposit'
                          ? `${NEOX_BLOCK_EXPLORER}/address/${transaction.to}`
                          : `${NEO_BLOCK_EXPLORER}/accountprofile/${transaction.to}`
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {currentTab === 'deposit'
                        ? formatAddress(transaction.to)
                        : formatAddress(
                            wallet.getAddressFromScriptHash(transaction.to.replace(/^0x/, '')),
                          )}
                    </a>
                  </div>
                </td>
                <td className="border-[1px] border-x-0 border-text/20">
                  <div className="flex items-center gap-[6px] pl-[16px]">
                    <div className="flex h-[24px] w-[24px] shrink-0 items-center justify-center rounded-full bg-text">
                      <img
                        src={`https://neo.org/images/gui/${
                          tokenMap?.[transaction?.symbol]?.n3Contract
                        }.png`}
                        className="w-[12px]"
                        onError={e => {
                          if (e.currentTarget.src !== 'https://neo.org/images/gui/default.png') {
                            e.currentTarget.src = 'https://neo.org/images/gui/default.png';
                          }
                        }}
                      />
                    </div>
                    <span>{formatDecimal(transaction.amount, 4)}</span>
                  </div>
                </td>
                <td className="rounded-br-[10px] rounded-tr-[10px] border-[1px] border-l-0 border-text/20 pl-[16px] text-left">
                  {timeAgo(transaction.timestamp)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {!transactions.length && !loading && (
          <div className="mb-10 text-center text-text/60">- No data -</div>
        )}
        {transactions.length > 0 && !loading && !hasMore && (
          <div className="mb-10 text-center text-text/60">- End -</div>
        )}
        {loading && <div className="mb-10 text-center">Loading more...</div>}
      </div>
    </div>
  );
};
